import type { History } from 'history';

import { type Session } from '@pushwoosh/session-store';
import { type ApplicationSummary } from '@pushwoosh/rpc-v2-http-api-data/applications';
import { type API as RpcGatewayAPI } from '@pushwoosh/rpc-v2-http-api-data';

const DEFAULT_MY_PROJECT_NAME = 'My Project';

const CHATBOT_CODE = `
  window.__ow = window.__ow || {};
  window.__ow.organizationId = "e45f652a-694d-4fec-8e03-509e1da98268";
  window.__ow.template_id = "c7412cab-5e76-4c2e-bb5a-77d77cd30c66";
  window.__ow.integration_name = "manual_settings";
  window.__ow.product_name = "chatbot";
  ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[OpenWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.openwidget.com/openwidget.js",t.head.appendChild(n)}};!n.__ow.asyncInit&&e.init(),n.OpenWidget=n.OpenWidget||e}(window,document,[].slice))
`;

function isElementShown(id: string): boolean {
  const element = document.getElementById(id);
  const opacity = +window.getComputedStyle(element!).opacity;
  return opacity > 0;
}

export function isUserActionsTrackingOff(): boolean {
  const turnOffTracking = localStorage.getItem('pw_off_user_actions_tracking');
  return turnOffTracking !== null && turnOffTracking !== '' && turnOffTracking !== '0' && turnOffTracking !== 'false';
}

function loadScript(id: string, src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');

      script.onload = (): void => {
        resolve();
      };
      script.onerror = (): void => {
        reject();
      };

      script.src = src;
      script.id = id;
      script.async = true;

      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}

function injectScript(id: string, code: string): void {
  if (!document.getElementById(id)) {
    const script = document.createElement('script');

    script.id = id;
    script.textContent = code;

    document.body.appendChild(script);
  }
}

export function loadExternalScripts(history: History): void {
  // Load 3rd party libraries and scripts
  if (isUserActionsTrackingOff()) {
    return;
  }
  const isOnPushwoosh = window.location.hostname.includes('pushwoosh.com');
  if (isOnPushwoosh) {
    injectScript('openwidget-chatbot', CHATBOT_CODE);

    const applyVisibility = (pathname: string): void => {
      try {
        const elShown = isElementShown('chat-widget-container');
        if (pathname.includes('/journeys')) {
          if (elShown) {
            window.OpenWidget.call('hide');
          }
        } else if (!elShown) {
          window.OpenWidget.call('minimize');
        }
      } catch (e) {
        console.error('Error applying visibility', e);
      }
    };
    window.OpenWidget.on('ready', () => {
      applyVisibility(history.location.pathname);
    });
    history.listen((location) => {
      applyVisibility(location.pathname);
    });

    // loadScript('ze-snippet', 'https://static.zdassets.com/ekr/snippet.js?key=f7ccd1c3-4384-4d19-9ce2-e79a04a11170')
    //   .then(() => isOnJourney && window.zE('webWidget', 'hide'));
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  loadScript('gtm', 'https://www.googletagmanager.com/gtm.js?id=GTM-MZSQXFB');

  loadScript('louAssist', '//run.louassist.com/v2.5.1-m?id=121647625417').then(() => {
    const louAssistEvent = new CustomEvent('louAssistLoaded');
    window.dispatchEvent(louAssistEvent);
  });
}

export async function checkShowWelcomeScreen(session: Session, applications: ApplicationSummary[]): Promise<boolean> {
  const hasPaid = session?.account?.productType !== 'Free';
  const hasConfiguredPlatform = applications?.some((application) => !application.isDemo && Object.values(application.platforms).some((platform) => !!platform.isConfigured));

  return !hasPaid && !hasConfiguredPlatform;
}

export async function goToMyProjectHomePage(rpcGateway: RpcGatewayAPI, history: History): Promise<void> {
  try {
    await rpcGateway.accounts.UpdateOnboardingInfo({ step: 'WelcomeScreen' });
    const { applications, total } = await rpcGateway.applications.List({ page: 0, perPage: 3 });

    if (total > 2) {
      /*
      if there are more apps in the list than DEMO APP and MY PROJECT, we don't need to redirect to the home page.
      We assume that the user has already seen this page and even created a new app.
      */
      return;
    }
    const myProject = applications.find((application) => application.title === DEFAULT_MY_PROJECT_NAME);
    if (myProject) {
      history.push(`/applications/${myProject.code}/home`);
    } else {
      const createResponse = await rpcGateway.applications.Create({ title: DEFAULT_MY_PROJECT_NAME });
      const myProjectCode = createResponse.application.code;
      if (myProjectCode) {
        history.push(`/applications/${myProjectCode}/home`);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export function identifyUserInLOU(session: Session): void {
  const louAssist = window.LOU;
  const { user, account } = session;

  if (louAssist) {
    louAssist.identify(user.email, {
      productType: account.productType,
      companyName: account.billing.companyName,
      registrationDate: account.registrationDate,
      email: user.email,
    });
  }
}

export function setApplicationCode(pathname: string = ''): void {
  const applicationsRegex = /\/applications\/([A-Z0-9-]+)\//i;
  const applicationCode = pathname.match(applicationsRegex)?.[1] || '';
  const currentValue = localStorage.getItem('lastUsedApplication');

  if (applicationCode && currentValue !== applicationCode) {
    localStorage.setItem('lastUsedApplication', applicationCode);
  }
}

export function getProductName(): string {
  if (origin.includes('app.omo-tech')) {
    return 'OMO Grow Management Platform';
  }

  return 'Pushwoosh';
}

export function getProductIcons(): string {
  if (origin.includes('app.omo-tech')) {
    return `
      <link rel="shortcut icon" href="/static/favicon-omo.png">
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon-omo.png" />
      <link rel="mask-icon" href="/static/safari-pinned-tab-omo.svg" color="#0F4DBA" />
    `;
  }

  return `
    <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon-pw.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
    <link rel="manifest" href="/static/site.webmanifest" />
    <link rel="mask-icon" href="/static/safari-pinned-tab-pw.svg" color="#0077ff" />
  `;
}
