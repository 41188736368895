import React from 'react';
import ReactDOM from 'react-dom';
import type { History } from 'history';

import { SessionStore } from '@pushwoosh/session-store';
import {
  type HttpClient, RpcClient, GrpcClient, Method,
} from '@pushwoosh/http-client';
import { createGrpcBridge } from '@pushwoosh/grpc-bridge';
import type { SPAContextType } from '@pushwoosh/smart-components-kit/context';
import { data, type API as RpcGatewayAPI } from '@pushwoosh/rpc-v2-http-api-data';
import { createHttpApi } from '@pushwoosh/rpc-v2-http-api';

import { Root } from '~/src/root.component';
import {
  getProductIcons,
  getProductName,
  loadExternalScripts,
} from './helpers/app.helpers';
import {
  getApiOrigin,
  getPhpApiOrigin,
} from '~/src/helpers/auth.helpers';

type StartMicroFrontendOptions = {
  history: History;
  httpClient: HttpClient;
};

export async function startMicroFrontend(options: StartMicroFrontendOptions): Promise<void> {
  const {
    history,
    httpClient,
  } = options;

  const rpcLog = localStorage.getItem('RPC_LOG') === '1';
  const rpcClient = new RpcClient(
    httpClient,
    {
      withAuthorization: true,
      log(info): void {
        if (rpcLog) {
          // eslint-disable-next-line no-console
          console.log(info);
        }
      },
      onException({ error }): void {
        console.error('rpcClient', error);
      },
      enviromentOrigin: getPhpApiOrigin(),
    },
  );

  const grpcHost = localStorage.getItem('GRPC_HOST');
  const grpcClient = new GrpcClient(
    grpcHost || getApiOrigin('rpc-api-grpcweb'),
    () => httpClient.getAccessToken(),
  );

  const billingGrpcClient = new GrpcClient(
    getApiOrigin('billing-grpcweb'),
    () => httpClient.getAccessToken(),
  );

  const journeyGrpcClient = new GrpcClient(
    getApiOrigin('journey-api-grpc'),
    () => httpClient.getAccessToken(),
  );

  const gpt3GrpcClient = new GrpcClient(
    getApiOrigin('gpt3-api-grpcweb'),
    () => httpClient.getAccessToken(),
  );

  const grpcBridge = createGrpcBridge({
    grpcClient,
    billingGrpcClient,
    journeyGrpcClient,
    gpt3GrpcClient,
  });
  grpcBridge.setEnableLogging(localStorage.getItem('PWGrpcBridgeLogging') === '1');

  const rpcGateway = createHttpApi<RpcGatewayAPI>(
    data,
    (method, url, body) => httpClient.request(
      `${getApiOrigin('rpc-api')}${url}`,
      method.toUpperCase() as Method,
      {
        withAuthorization: true,
        body,
      } as any,
    ),
  );

  // create an instance of SessionStore to manage session data
  const sessionStore = new SessionStore(rpcGateway);
  // load the current session data
  await sessionStore.update();

  const spaContext: SPAContextType = {
    history,
    httpClient,
    rpcClient,
    grpcClient,
    grpcBridge,
    sessionStore,
    rpcGateway,
    language: localStorage.getItem('PW_LANGUAGE') || 'en',
    helpers: {
      getApiOrigin,
    },
    __smartComponentsCache: {},
  };

  const htmlCollectionTitle = document.getElementsByTagName('title');
  const [title] = Array.from(htmlCollectionTitle);
  if (title && title.innerText) {
    title.innerText = getProductName();
  }

  const htmlCollectionHead = document.getElementsByTagName('head');
  const [head] = Array.from(htmlCollectionHead);
  if (head && head.innerText) {
    head.innerHTML += getProductIcons();
  }

  try {
    ReactDOM.render(
      (
        <Root
          history={history}
          httpClient={httpClient}
          rpcClient={rpcClient}
          grpcClient={grpcClient}
          grpcBridge={grpcBridge}
          billingGrpcClient={billingGrpcClient}
          journeyGrpcClient={journeyGrpcClient}
          sessionStore={sessionStore}
          spaContext={spaContext}
        />
      ),
      document.getElementById('root'),
      () => {
        loadExternalScripts(history);
      },
    );

    document.body.classList.add('is-loaded');
  } catch (error) {
    console.error('index.app', error);
    document.body.classList.add('is-crashed');
  }
}
