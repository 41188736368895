import { createEventListener, deleteEventListener } from '../../utils.js';
import Maintainer from './maintainer.js';
export default class Nodes {
    constructor(params) {
        this.nodes = new Map();
        this.totalNodeAmount = 0;
        this.nodeCallbacks = [];
        this.elementListeners = new Map();
        this.nextNodeId = 0;
        // Attached once per Tracker instance
        this.attachNodeCallback = (nodeCallback) => {
            return this.nodeCallbacks.push(nodeCallback);
        };
        this.scanTree = (cb) => {
            this.nodes.forEach((node) => (node ? cb(node) : undefined));
        };
        this.attachNodeListener = (node, type, listener, useCapture = true) => {
            const id = this.getID(node);
            if (id === undefined) {
                return;
            }
            createEventListener(node, type, listener, useCapture, this.forceNgOff);
            let listeners = this.elementListeners.get(id);
            if (listeners === undefined) {
                listeners = [];
                this.elementListeners.set(id, listeners);
            }
            listeners.push([type, listener, useCapture]);
        };
        this.unregisterNode = (node) => {
            const id = node[this.node_id];
            if (id !== undefined) {
                ;
                node[this.node_id] = undefined;
                delete node[this.node_id];
                this.nodes.delete(id);
                const listeners = this.elementListeners.get(id);
                if (listeners !== undefined) {
                    this.elementListeners.delete(id);
                    listeners.forEach((listener) => deleteEventListener(node, listener[0], listener[1], listener[2], this.forceNgOff));
                }
                this.totalNodeAmount--;
            }
            return id;
        };
        this.node_id = params.node_id;
        this.forceNgOff = params.forceNgOff;
        this.maintainer = new Maintainer(this.nodes, this.unregisterNode, params.maintainer);
        this.maintainer.start();
    }
    syntheticMode(frameOrder) {
        const maxSafeNumber = Number.MAX_SAFE_INTEGER;
        const placeholderSize = 99999999;
        const nextFrameId = placeholderSize * frameOrder;
        // I highly doubt that this will ever happen,
        // but it will be easier to debug if it does
        if (nextFrameId > maxSafeNumber) {
            throw new Error('Placeholder id overflow');
        }
        this.nextNodeId = nextFrameId;
    }
    registerNode(node) {
        let id = node[this.node_id];
        const isNew = id === undefined;
        if (isNew) {
            id = this.nextNodeId;
            this.totalNodeAmount++;
            this.nextNodeId++;
            this.nodes.set(id, node);
            node[this.node_id] = id;
        }
        return [id, isNew];
    }
    cleanTree() {
        // sadly we keep empty items in array here resulting in some memory still being used
        // but its still better than keeping dead nodes or undef elements
        // plus we keep our index positions for new/alive nodes
        // performance test: 3ms for 30k nodes with 17k dead ones
        for (const [_, node] of this.nodes) {
            if (node && !document.contains(node)) {
                this.unregisterNode(node);
            }
        }
    }
    callNodeCallbacks(node, isStart) {
        this.nodeCallbacks.forEach((cb) => cb(node, isStart));
    }
    getID(node) {
        if (!node)
            return undefined;
        return node[this.node_id];
    }
    getNode(id) {
        return this.nodes.get(id);
    }
    getNodeCount() {
        return this.totalNodeAmount;
    }
    clear() {
        for (const [_, node] of this.nodes) {
            if (node) {
                this.unregisterNode(node);
            }
        }
        this.nextNodeId = 0;
        this.nodes.clear();
    }
}
