import Tracker from '@openreplay/tracker';

import { Account, User } from '@pushwoosh/session-store';
import { getMixpanelDistinctId } from './mixpanel';
import { isUserActionsTrackingOff } from './app.helpers';

const isProductionMode = process.env['NODE_ENV'] === 'production';

const projectToken = 'En1WwFrGb3SI1CAuecqC';

function setHashToCookie(openReplayHash: string): void {
  const expires = new Date(Date.now() + 8 * 3600000).toUTCString();
  document.cookie = `openreplay_session=${openReplayHash};expires=${expires};path=/;domain=.pushwoosh.com;`;
}

function getSessionHash(): string {
  // Try to get hash from cookie first
  const sessionCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('openreplay_session='));
  if (sessionCookie) {
    return sessionCookie.split('=')[1] ?? '';
  }

  // Try to get hash from sessionStorage
  const storedHash = sessionStorage.getItem('__openreplay_token');

  if (storedHash) {
    setHashToCookie(storedHash);
    return storedHash;
  }

  return '';
}

const tracker = new Tracker({
  projectKey: projectToken,
  ingestPoint: 'https://open-replay.pushwoosh.com/ingest',
  disableStringDict: true,
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: true,
  __debug__: 4,
});

export function initOpenReplaySession(): void {
  if (!isProductionMode || isUserActionsTrackingOff()) {
    return;
  }

  const storedHash = getSessionHash();
  tracker.start({ userID: getMixpanelDistinctId() ?? undefined, sessionHash: storedHash }).then((sessionInfo) => {
    if (!storedHash && sessionInfo.success) {
      // eslint-disable-next-line no-console
      console.log('New open replay session created, set cookie with', sessionInfo.sessionToken);
      setHashToCookie(sessionInfo.sessionToken);
    }
    if (!sessionInfo.success) {
      console.error('Error starting open replay session: ', sessionInfo.reason);
    }
  });
}

export function identifyOpenReplaySession(user: User, account: Account): void {
  if (!isProductionMode || isUserActionsTrackingOff()) {
    return;
  }

  tracker.setUserID(user.email);
  tracker.setMetadata('AccountID', account.id.toString());
}
