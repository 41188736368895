const SECOND = 1000;
function processMapInBatches(map, batchSize, processBatchCallback) {
    const iterator = map.entries();
    function processNextBatch() {
        const batch = [];
        let result = iterator.next();
        while (!result.done && batch.length < batchSize) {
            batch.push(result.value);
            result = iterator.next();
        }
        if (batch.length > 0) {
            batch.forEach(([_, node]) => {
                if (node) {
                    processBatchCallback(node);
                }
            });
            setTimeout(processNextBatch, 50);
        }
    }
    processNextBatch();
}
function isNodeStillActive(node) {
    try {
        if (!node.isConnected) {
            return [false, 'not connected'];
        }
        const nodeIsDocument = node.nodeType === Node.DOCUMENT_NODE;
        const nodeWindow = nodeIsDocument
            ? node.defaultView
            : node.ownerDocument?.defaultView;
        const ownerDoc = nodeIsDocument ? node : node.ownerDocument;
        if (!nodeWindow) {
            return [false, 'no window'];
        }
        if (nodeWindow.closed) {
            return [false, 'window closed'];
        }
        if (!ownerDoc?.documentElement.isConnected) {
            return [false, 'documentElement not connected'];
        }
        return [true, 'ok'];
    }
    catch (e) {
        return [false, e];
    }
}
const defaults = {
    interval: SECOND * 30,
    batchSize: 2500,
    enabled: true,
};
class Maintainer {
    constructor(nodes, unregisterNode, options) {
        this.nodes = nodes;
        this.unregisterNode = unregisterNode;
        this.start = () => {
            if (!this.options.enabled) {
                return;
            }
            this.stop();
            this.interval = setInterval(() => {
                processMapInBatches(this.nodes, this.options.batchSize, (node) => {
                    const isActive = isNodeStillActive(node)[0];
                    if (!isActive) {
                        this.unregisterNode(node);
                    }
                });
            }, this.options.interval);
        };
        this.stop = () => {
            if (this.interval) {
                clearInterval(this.interval);
            }
        };
        this.options = { ...defaults, ...options };
    }
}
export default Maintainer;
