import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getApiOrigin } from './auth.helpers';
import { trackMixpanelEvent } from './mixpanel';
import { isUserActionsTrackingOff } from './app.helpers';

interface Thresholds {
  [key: number]: boolean;
}

interface QueryParams {
  [key: string]: string;
}

interface MetricAttributes {
  ln: string;
  href?: string | null;
  acc_id?: number | null;
  userEmail?: string | null;
  app_id?: string;
  utm?: QueryParams;
  queryParams?: QueryParams;
  clickedText?: string | null;
  threshold?: number;
  n_user?: boolean;
}

interface MetricData {
  user_id?: string;
  action_type: string;
  attributes: MetricAttributes;
}

const handledInputTypes = ['radio', 'checkbox', 'button', 'submit'];

function getPage(): string {
  return `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
}

function extractAppId(url: string): string | null | undefined {
  const pattern = /\/(applications|journeys)\/([^/]+)/;
  const match = url.match(pattern);

  return match ? match[2] : null;
}

function sendMetrics(data: MetricData): void {
  if (process.env['NODE_ENV'] !== 'production' || isUserActionsTrackingOff()) {
    return;
  }

  const urlWithoutParams = getPage();
  const appId = extractAppId(window.location.href);

  try {
    trackMixpanelEvent({
      name: data.action_type,
      properties: {
        page: urlWithoutParams,
        user_id: data.user_id,
        ...data.attributes,
      },
    });
  } catch (e) {
    console.error('Error sending trackMixpanelEvent:', {
      page: urlWithoutParams,
      user_id: data.user_id,
      ...data.attributes,
    }, e);
  }

  const fullData = {
    page: urlWithoutParams,
    ...data,
    attributes: JSON.stringify({ ...data.attributes, ...(appId && { app_id: appId }) }),
  };
  navigator.sendBeacon(`${getApiOrigin('user-action')}/api/v1/statistics/user-action/collect`, JSON.stringify(fullData));
}

function getQueryParams(queryString: string = window.location.search): { utm: QueryParams, queryParams: QueryParams } {
  const utm: QueryParams = {};
  const queryParams: QueryParams = {};
  try {
    if (queryString === '') {
      return { utm, queryParams };
    }

    const query = queryString.startsWith('?') ? queryString.substring(1) : queryString;

    query.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key !== undefined && value !== undefined) {
        const paramName = key.replace(/\+/g, ' ');
        const paramValue = decodeURIComponent(value.replace(/\+/g, ' '));

        if (paramName.startsWith('utm_')) {
          utm[paramName] = encodeURIComponent(paramValue);
        } else {
          queryParams[paramName] = encodeURIComponent(paramValue);
        }
      }
    });

    return { utm, queryParams };
  } catch (e) {
    console.error('Error decoding URL component (getQueryParams):', queryString, e);
  }
  return { utm, queryParams };
}

function getClickableParentText(element: HTMLElement | null): { isClickableElement: boolean, clickedText: string | null } {
  let curElement = element;

  while (curElement && curElement !== document.body) {
    if (curElement.onclick && typeof curElement.onclick === 'function') {
      return { isClickableElement: true, clickedText: curElement.textContent };
    }

    curElement = curElement.parentElement;
  }

  return { isClickableElement: false, clickedText: null };
}

export class UserMetricsCollector {
  private ln: string;

  private user_id?: string;

  private acc_id?: number;

  private userEmail?: string;

  private thresholds: Thresholds;

  constructor() {
    this.ln = navigator.language;
    this.thresholds = {
      25: false, 50: false, 75: false, 100: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onMessage = this.onMessage.bind(this);
  }

  public onRouteChange(): void {
    this.user_id = Cookies.get('user_id');
    const isFirstTimeVisit = !this.user_id;

    if (!this.user_id) {
      this.user_id = uuidv4();
      Cookies.set('user_id', this.user_id, {
        domain: '.pushwoosh.com',
        path: '/',
      });
    }

    const { queryParams, utm } = getQueryParams();

    sendMetrics({
      user_id: this.user_id,
      action_type: 'PAGE_VISIT',
      attributes: {
        ln: this.ln,
        ...(Object.keys(queryParams).length && { queryParams }),
        ...(Object.keys(utm).length && { utm }),
        acc_id: this.acc_id,
        userEmail: this.userEmail,
        ...(isFirstTimeVisit && { n_user: isFirstTimeVisit }),
      },
    });
  }

  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    const link = target.closest('a');
    const button = target.closest('button');
    const input = target.closest('input');
    const trackAction = target.getAttribute('data-track-action');

    const isButtonClicked = target.tagName === 'BUTTON' || button !== null;
    const isLinkClicked = link !== null;
    const isInputClicked = input !== null && handledInputTypes.includes(input.type);

    const actionType: string = 'CLICK';

    if (isButtonClicked || isLinkClicked || isInputClicked || trackAction) {
      const href = isLinkClicked ? link.href : null;
      const clickedText = isInputClicked ? target.closest('label')?.textContent : target.textContent;

      sendMetrics({
        user_id: this.user_id,
        action_type: actionType,
        attributes: {
          ln: this.ln,
          ...(isLinkClicked && { href }),
          clickedText,
          acc_id: this.acc_id,
          userEmail: this.userEmail,
          ...(trackAction && { trackAction }),
        },
      });
    } else {
      const { isClickableElement, clickedText } = getClickableParentText(target);
      if (isClickableElement) {
        sendMetrics({
          user_id: this.user_id,
          action_type: actionType,
          attributes: {
            ln: this.ln,
            clickedText,
            acc_id: this.acc_id,
            userEmail: this.userEmail,
          },
        });
      }
    }
  }

  public onScroll(): void {
    const scrolledPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;

    Object.keys(this.thresholds).forEach((key) => {
      const threshold = Number(key);
      if (scrolledPercentage >= threshold && !this.thresholds[threshold]) {
        sendMetrics({ user_id: this.user_id, action_type: 'SCROLL', attributes: { ln: this.ln, threshold, acc_id: this.acc_id } });

        this.thresholds[threshold] = true;
      }
    });
  }

  public onMessage(event: MessageEvent): void {
    if (!event.origin.includes('youtube')) return;

    try {
      const youtubeData = JSON.parse(event.data);

      // Проверяем, что это сообщение от YouTube и action = 1 (нажали на play)
      if (youtubeData && youtubeData.info && youtubeData.event === 'infoDelivery' && youtubeData.info.playerState === 1) {
        // Получаем URL видео
        const videoUrl = youtubeData.info.videoUrl || 'URL не найден';

        sendMetrics({
          user_id: this.user_id,
          action_type: 'CLICK',
          attributes: { ln: this.ln, href: videoUrl, clickedText: 'Youtube video play' },
        });
      }
    } catch (e) {
      console.error('Error parsing YouTube event:', e);
    }
  }

  public setUserData(accountId: number, email: string): void {
    this.acc_id = accountId;
    this.userEmail = email;
  }

  public subscribe(): void {
    this.onRouteChange();
    window.addEventListener('click', this.onClick, true);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('message', this.onMessage);
  }

  public unsubscribe(): void {
    window.removeEventListener('click', this.onClick, true);
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('message', this.onMessage);
  }
}
