export class StringDictionary {
    constructor() {
        this.lastTs = 0;
        this.lastSuffix = 1;
        /** backwards dictionary of
         * [repeated str:key]
         * */
        this.backDict = {};
        this.getKey = (str) => {
            let isNew = false;
            // avoiding potential native object properties
            const safeKey = `__${str}`;
            if (!this.backDict[safeKey]) {
                isNew = true;
                // shaving the first 2 digits of the timestamp (since they are irrelevant for next millennia)
                const shavedTs = Date.now() % 10 ** (13 - 2);
                let id = shavedTs;
                if (id === this.lastTs) {
                    id = id * 10000 + this.lastSuffix;
                    this.lastSuffix += 1;
                }
                else {
                    this.lastSuffix = 1;
                }
                this.backDict[safeKey] = id;
                this.lastTs = shavedTs;
            }
            return [this.backDict[safeKey], isNew];
        };
    }
}
export default class AttributeSender {
    constructor(options) {
        this.sendSetAttribute = (id, name, value) => {
            if (this.isDictDisabled) {
                const msg = [12 /* Type.SetNodeAttribute */, id, name, value];
                return this.app.send(msg);
            }
            else {
                const message = [
                    35 /* Type.SetNodeAttributeDictGlobal */,
                    id,
                    this.applyDict(name),
                    this.applyDict(value),
                ];
                return this.app.send(message);
            }
        };
        this.app = options.app;
        this.isDictDisabled = options.isDictDisabled;
        this.dict = new StringDictionary();
    }
    applyDict(str) {
        const [key, isNew] = this.dict.getKey(str);
        if (isNew) {
            this.app.send([34 /* Type.StringDictGlobal */, key, str]);
        }
        return key;
    }
    clear() {
        this.dict = new StringDictionary();
    }
}
